<template>
  <div class="paymoney">
    <ActionSheet 
      :close-on-click-overlay="false"
      v-model="show" 
      round :title=" '支付 ' + money + '元'" 
      style="height:70%;" 
      @cancel="onCancel" 
    >
      <span class="mima" @click.stop="goMin">忘记密码</span>
      <PasswordInput :value="pwd" 
        :focused="showKeyboard"
        @focus="showKeyboard = true" 
        :info="`${paytype == 7 ? passType:'密码为 6 位数字'}`"
        @input="onInput"
        @delete="onDelete"
      >
      </PasswordInput>
      <NumberKeyboard
        :show="show"
        theme="default"
        v-model="pwd"
        :safe-area-inset-bottom="true"
        close-button-text="完成"
        @input="onInput"
        @delete="onDelete"/>
    </ActionSheet>
  </div>
</template>
<script>
import Setting from "@/api/setting"
import { PasswordInput, NumberKeyboard, Toast, ActionSheet  } from 'vant';
import Order from "@/api/order";
export default {
  components: { NumberKeyboard, ActionSheet, PasswordInput },
  props: {
    money: {
      type: String
    },
    ordersn: {
      type: String
    },
    teamCardId: {
      type: String
    },
    paytype: {
      type: Number
    },
    point: {
      type: Number
    },
    passType: {
      type: String
    }
  },
  data() {
    return {
      pwd: "",
      show: false,
      showKeyboard: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      sessionStorage.setItem("path", `${this.$route.fullPath}`);
    })
  },
  methods: {
    goMin() {
      sessionStorage.setItem("verifyType", "dealPassword");
      this.$router.push("/userSafety");
    },
    // 显示支付弹窗
    showPayToast() {
      this.show = true;
    },
    closePayToast() {
      this.show = false;
      this.pwd = "";
    },
    onCancel() {
      Toast('取消');
    },
    onInput(value) {
      this.pwd = (this.pwd + value).slice(0, 6);
      if (this.pwd.length === 6) {
        switch (this.paytype) {
          case 2:
            this.balancePay();
            break;
          case 4:
            this.goPay();
            break;        
          case 5:
            this.$parent.make_card(this.pwd);
            this.pwd = "";
            break;        
          case 6:
            this.oliVipPay();
            break;        
          case 7:
            this.teamCardPay();
            break;        
          case 8:
            this.familyCardPay();
            break;
          default:
            break;
        }
      }
    },
    onDelete() {
      this.pwd = this.pwd.slice(0, this.pwd.length - 1);
    },
    // 余额支付
    async balancePay() {
      try {
        const res = await Order.goPayOrder(this.ordersn, "balance");
        if (res.code == 200) {
          this.$toast('支付成功');
          setTimeout(() => {
            this.$router.push({
              path: '/order'
            })
          }, 800)
        } else {
          throw(res.msg);
        }
      } catch (err) {
        this.$toast(err);
      }
    },
    // 石油会员卡项目 车队卡支付
    async teamCardPay() {
      console.log(this.$parent.teamCardId)
      if (await this.verOldPayPassword(this.pwd,1,this.$parent.teamCardId)) {
        this.$parent.oil_password = this.pwd;
        this.$parent.ewmCreateOrder("team_balance");
        this.$toast.loading({
          message: "正在支付...",
          forbidClick: true
        });
        this.pwd = "";
      }
    },
    // 石油会员卡项目 亲属卡
    async familyCardPay() {
      if (await this.verOldPayPassword(this.pwd,0,"")) {
        this.$parent.oil_password = this.pwd;
        this.$parent.ewmCreateOrder("family_balance");
        this.$toast.loading({
          message: "正在支付...",
          forbidClick: true
        });
        this.pwd = "";
      }
    },
    // 石油会员卡项目 余额支付
    async goPay() {
      if (await this.verOldPayPassword(this.pwd,0,"")) {
        this.$parent.oilBalancePay(this.pwd);
        this.$toast.loading({
          message: "正在支付...",
          forbidClick: true
        });
        this.pwd = "";
      }
    },
    // 油卡支付
    async oliVipPay() {
      if (await this.verOldPayPassword(this.pwd,0,"")) {
        this.$parent.subOilCard_pay(this.pwd);
        this.$toast.loading({
          message: "正在支付...",
          forbidClick: true
        });
        this.pwd = "";
      }
    },
    // 验证支付密码
    async verOldPayPassword(value,type,account_id) {
      try {
        const res = await Setting.verifyPayPass(value,type,account_id);
        if (res.code == 200) {
          return true;
        } else {
          this.$toast(res.msg);
          this.pwd = "";
          return false;
        }
      } catch(err) {
        console.log(err);
      }
    },
  }
    
}
</script>
<style lang="scss" scoped>
.paymoney {
  width: 100%;
  height: auto;
  overflow: hidden;
  .mima {
    position: relative;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    font-size: 14px;
    padding-left: 20px;
    z-index: 3000;
  }
}
</style>
